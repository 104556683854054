window._ = require('lodash');

try {
    require('bootstrap');
} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.bootstrap = require('bootstrap');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.baseURL = window.location.origin;
const headerAuthToken = `Bearer ${CookieUtils.getCookie('AuthTokenValue')}`;
window.axios.defaults.headers.common['Authorization'] = `${headerAuthToken}`;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */
import Echo from 'laravel-echo';

//si es https forcar tls

if (window.location.pathname !== "/login") {
    const forceTLS = window.location.protocol === 'https:';

    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
        broadcaster: 'pusher',
        authEndpoint: "/broadcasting/auth",
        key: process.env.MIX_PUSHER_APP_KEY,
        cluster: process.env.MIX_PUSHER_APP_CLUSTER,
        wsHost: process.env.MIX_PUSHER_HOST,
        wsPort: process.env.MIX_PUSHER_PORT,
        wssPort: process.env.MIX_PUSHER_PORT,
        forceTLS: forceTLS,
        disableStats: true,
        encrypted: true,
        enabledTransports: ['ws', 'wss'],
    });
}


