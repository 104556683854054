import Swal from 'sweetalert2'

/* Importanco las utilizades comunes */

import CookieUtils from '../../resources/js/common/cookie_utils.js';
import WebSocketUtils from '../../resources/js/common/ws_utils.js';
import Utils from '../../resources/js/common/utils.js';

require('./bootstrap.js');
require('./dataTables.rowsGroup.js');



/**
 *
 * @param {string} title
 * @param {string} msg
 * @param {string['success', 'error', 'warning', 'info', 'question']} type This is a parameter
 * @param {string[ 'top-left',   'top',   'top-right',
 *                 'center-left','center','center-right',
 *                 'bottom-left','bottom','bottom-right']} position This is a parameter
 * @param {number} timer Time to fade out
 * @returns {void}
 */
var notificationCounter = 0;
function showNotification (msg,title = '', type = 'success', position = 'bottom-right', timer = 2500){
    title = title??'';
    type = type??'success';
    position = position??'bottom-right';
    timer = timer??1500;

    notificationCounter++;
    var id = `notification-${notificationCounter}`;

    $('#notification-wrapper')
        // $('body')
        .append(`<div id="${id}" class="card notification ${id} ${type} ${position}">
            ${title && title.length
                ? `<div class="card-header">${title}</div>
                    <div class="card-body">${msg}</div>`
                : `<div class="card-header only-notification-header">${msg}</div>`
             }
        </div>`)

    let actualNotificationNumber = $(`.notification.${position}`).length;
    if(actualNotificationNumber){
        $(`.notification.${position}:not(#${id})`).each(function (index){
            if(position.includes('top')) {
                let top = Number($(this).css('top').replace('px', ''))
                $(this).css('top', `calc(${top + $(`#${id}`).height()}px + 0.5rem)`)
            }
            else if (position.includes('bottom')) {
                let bottom = Number($(this).css('bottom').replace('px', ''))
                $(this).css('bottom', `${bottom+$(`#${id}`).height() + 10}px` )
            }
        })
    }
    // Desaparicion
    if(timer != -1){
        setTimeout(function(){
            $(`#${id}`).addClass('fade')
            setTimeout(function(){
                $(`#${id}`).remove()
                //TODO: Al borrar, recolocar las tarjetas
            }, 150)
        }, timer)
    }

}


Object.assign(window, {
    'Swal': Swal,
    'formatNumber': function (number,maxDigits=4) {
        if (isNaN(number)) return null;
        return number.toLocaleString('de-DE', {
            minimumFractionDigits: 0,
            maximumFractionDigits: maxDigits
        });
    },
    'decodeHtml': function (html) {
        return $('<div>').html(html).text();
    },
    'confirmForm': function (event) {
        event.preventDefault();
        Swal.fire({
            icon: 'warning',
            title: '¿Estas seguro?',
            showCancelButton: true,
            confirmButtonColor: '#28A745',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'
        }).then(result => {
            if(result.isConfirmed){
                $($(event.target).parents('form')[0]).submit()
            }
        })
    },
    'sendFormWithAxios': function (form) {
        var actionUrl = form.attr('action');
        var formType = form.attr('method');
        axios({
            method: formType,
            url: actionUrl,
            data: form.serialize(), // serializes the form's elements.
        })
        .then(response => {
            console.log('Success ( ^^)b', response.data); // show response from the server.
        })
        .catch(error => {
            console.error(error);
        });
    },
    'showNotification':  showNotification
})
$(document).ready(function () {
    $('.chosen').chosen();
});


