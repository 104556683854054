module.exports = Utils  = {
    isString(data) {
        return typeof data === 'string' || data instanceof String;
    },
    askToContinue(message,icon,  confirmText = 'Si', cancelText = 'No' ){
        return Swal.fire({
            icon: 'warning',
            title: message,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#28A745',
            confirmButtonText: confirmText,
            cancelButtonText: cancelText,
        })
    },
    /**
     *
     * @param {number} number
     * @param {number} precision Number of decimal digits
     * @returns {number}
     */
    round(number, precision = 0) {
        let factor = 10 ** precision;
        return Math.round(number * factor) / factor;
    },

    /**
     *
     * @param {string} message
     * @param {number} timer
     * @param {string['success', 'error', 'warning', 'info', 'question']} icon This is a parameter
     * @returns {Promise<SweetAlertResult<Awaited<any>>>}
     */
    showAlert(message, timer = null, icon = '') {
        return Swal.fire({
            ...{
                icon: icon,
                title: message,
                showCancelButton: false
            },
            ...(!!timer) && {timer: timer}
        })
    },
    formatDate(date){
        if(!date) return "N/A";
        if(typeof date == 'string') date =  new Date(date);

        return date.toLocaleString().replaceAll(',','')
    },
}